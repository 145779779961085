<template>
  <div class="project-edit">
    <!-- <headers></headers> -->
    <div class="project-edit-wrapper manage-wrapper">
      <!-- <el-breadcrumb separator="/" class="bread-crumb">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/home/manage' }">项目课管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑课程</el-breadcrumb-item>
      </el-breadcrumb> -->
      <div class="project-edit-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="编辑课程" name="first">
            <el-form ref="projectEditForm" :model="form" label-width="180px" :rules="rules">

              <el-form-item label="课程名称：" prop="name">
                <el-input v-model="form.name" maxlength="100" show-word-limit style="width:400px"></el-input>
              </el-form-item>

              <el-form-item label="课程简介：">
                <el-input type="textarea" class="textarea" :rows="6" maxlength="2500" show-word-limit
                  v-model="form.courseExplain" resize="none">
                </el-input>
              </el-form-item>

              <el-form-item label="是否为精品课：" prop="boutique" required>
                <el-radio-group v-model="form.boutique">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <UserGroup :id="Number($route.query.proId)" @userGroup="changeUserGroup" :userGroupVal="form.userGroupIds"
                :proId="form.projectId" :isBoutique="form.boutique" :isEdit="true"></UserGroup>

              <el-form-item label="授课模式：">
                <el-radio-group v-model="form.lessonsPattern" disabled>
                  <el-radio :label="0">同步课</el-radio>
                  <el-radio :label="1">异步课</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="班型：" v-show="form.lessonsPattern == 0">
                <el-radio-group v-model="form.classType" disabled>
                  <el-radio v-for="(item,index) in classList" :key="index" :label="item.id">{{item.name}}</el-radio>
                  <!-- <el-radio disabled :label="2">百家云大班普通课</el-radio>
                  <el-radio disabled :label="3">百家云大班伪直播</el-radio>
                  <el-radio disabled :label="4">百家云小班课</el-radio>
                  <el-radio disabled :label="5">classin标准课</el-radio>
                  <el-radio disabled :label="6">腾讯云课堂</el-radio> -->
                </el-radio-group>
              </el-form-item>

              <el-form-item v-show="form.lessonsPattern == 1" label="课程有效期：" prop="lessonsInDate">
                <el-select v-model="form.lessonsInDate" disabled style="width:555px">
                  <el-option label="永久有效" value="1"></el-option>
                  <el-option label="一年" value="2"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="课程类型：">
                <CourseTypeSelect @courseType="changeCourseType" :editSelected="form.courseTypeId" isEdit>
                </CourseTypeSelect>
              </el-form-item>

              <el-form-item label="课程学科：">
                <SubjectSelect @subject="changeSubject" :editSelected="form.subjectId" isEdit></SubjectSelect>
              </el-form-item>

              <el-form-item label="主题封面：">
                <UploadCover @uploadCover="handleCoverChange" :xcover="form.cover"></UploadCover>
              </el-form-item>

              <div class="teahcers flex-align" v-if="form.lessonsPattern == 0">
                <el-form-item label="授课老师：" prop="teacherId">
                  <cTeacherSelect :requestType="'teacher'" :userGroup="form.userGroupIds"
                    @teacherSelect="handleTeacherChange" :role="form.teacherRole" :selected="form.teacherId" />
                </el-form-item>

                <el-form-item label="助教老师：" label-width="120px">
                  <cTeacherSelect :requestType="'assistant'" :userGroup="form.userGroupIds"
                    @teacherSelect="handleTeacherChange" :role="form.assistantRole" :selected="form.assistantId" />
                </el-form-item>
              </div>

              <!-- <el-form-item label="主讲教师：" class="teahcers" v-if="form.lessonsPattern == 1">
                <el-input v-model="form.teacherName" style="width:300px" placeholder="请填写主讲教师名称"></el-input>
              </el-form-item> -->

              <el-form-item label="课程标签：">
                <el-input v-model="form.courseLabel" maxlength="30" show-word-limit style="width:555px"></el-input>
              </el-form-item>

              <el-form-item class="el-form-item-btns">
                <el-button @click="$router.push('/home/manage')">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import cTeacherSelect from "./components/courseTeacherSelect.vue";
  export default {
    components: {
      cTeacherSelect
    },
    data() {
      return {
        activeName: 'first',
        // 表单验证规则
        rules: {
          name: [{
            required: true,
            message: "请填写课程名称",
            trigger: "blur"
          }],
          teacherId: [{
            required: true,
            message: "请选择授课老师",
            trigger: "change"
          }],
          teacherName: [{
            required: true,
            message: "请填写教师名称",
            trigger: "change"
          }],
        },
        form: {
          classType: '', //班型
          lessonsInDate: 1, //课程有效期,
          boutique: 0,
        },
        submitForm: {
          name: undefined,
          courseExplain: undefined,
          trancheId: undefined,
          projectId: undefined,
          userGroupIds: undefined,
          lessonsPattern: undefined,
          classType: undefined,
          lessonsInDate: undefined,
          courseTypeId: undefined,
          subjectId: undefined,
          cover: undefined,
          assistantId: undefined,
          assistantRole: undefined,
          teacherId: undefined,
          teacherRole: undefined,
          courseLabel: undefined,
          boutique: undefined,
        },
        // 班型列表
        classList: []
      };
    },
    methods: {
      // 切换用户组
      changeUserGroup(data) {
        // console.log(data);
        this.form.trancheId = data.periodId;
        this.form.projectId = data.projectId;
        this.form.userGroupIds = data.userGroupArr;
        // console.log(this.form);
      },
      // 处理封面
      handleCoverChange(data) {
        // console.log(data)
        this.form.cover = data.md5
      },
      // 切换课程类型
      changeCourseType(data) {
        // console.log(data);
        this.form.courseTypeId = data;
      },
      // 切换学科
      changeSubject(data) {
        // console.log(data);
        this.form.subjectId = data;
      },
      // 切换教师
      handleTeacherChange(data) {
        // console.log(data);
        let type = data.type === 'teacher' ? true : (data.type === 'assistant' ? false : '')
        if (type) {
          this.form.teacherId = data.teacherId
          this.form.teacherRole = data.role
        } else {
          this.form.assistantId = data.teacherId
          this.form.assistantRole = data.role
        }
        // console.log(this.form);
      },
      // 获取课程信息
      async getfindCourse() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.findCourse(id);
        console.log(resData);
        this.form = resData.data;
        Vue.set(this.form, 'projectId', resData.data.project.id);
        Vue.set(this.form, 'trancheId', resData.data.project.parentId);
        Vue.set(this.form, 'assistantId', resData.data.assistant ? resData.data.assistant.id : "");
        if(this.form.lessonsPattern == 1){
        }else{
          Vue.set(this.form, 'teacherId', resData.data.teacher.id);
        }
        resData.data.courseTypeEntity ? Vue.set(this.form, 'courseTypeId', resData.data.courseTypeEntity.id) : '';
        resData.data.subject ? Vue.set(this.form, 'subjectId', resData.data.subject.id) : '';
        let arr = [];
        (this.form.userGroupIds.split(',').map(item => {
          arr.push(Number(item));
        }))
        Vue.set(this.form, 'userGroupIds', arr)
      },
      // 拷贝需要提交的表单项
      copyForm() {
        for (const key in this.submitForm) {
          if (this.form.lessonsPattern == 0 && key == 'lessonsInDate') continue;
          if (this.form.lessonsPattern == 1 && key == 'classType') continue;
          this.submitForm[key] = this.form[key];
        }
        Vue.set(this.submitForm, 'id', this.$route.params.id)

        // console.log('submitForm',this.submitForm);
      },
      // 处理列表
      handleList(list) {
        let commonFuc = ((el, name) => {
          if (list[el]) {
            list[name] = list[el].id
          }
        })

        // commonFuc('teacher','teacherzId')
        // commonFuc('assistant','assistantId')
        commonFuc('courseTypeEntity', 'courseTypeEntity')
        commonFuc('subject', 'subject')
      },
      // 提交
      async onSubmit() {
        this.$refs['projectEditForm'].validate((valid) => {
          if (valid) {
            this.copyForm();
            this.commitForm();
          } else {
            this.$message.error({
              title: "错误",
              message: "请正确填写数据"
            });
          }
        })
      },
      async commitForm() {
        // console.log(this.form);
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        let resData = await this.$Api.Course.editCourse(this.form);
        // console.log(resData);
        if (resData.data.code == 200) {
          loading.close();
          // this.$router.push({
          //   path: `/home/manage/project`
          // })
          this.$router.go(-1)
        } else {
          loading.close();
          this.$message.error({
            title: "错误",
            message: "请正确填写数据"
          });
        }
      },
      async getClassType() {
        let data = {
          projectId: this.$store.state.user.currentProject.id
        }
        let resData = await this.$Api.Course.getClassType(data)
        this.classList = resData.data
      }
    },
    mounted() {
      this.getfindCourse();
      this.getClassType()
    }
  };
</script>

<style lang="less" scoped>
  @import "./less/manage-project.less";
</style>